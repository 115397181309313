import { useCallback, type ReactNode } from "react";
import { $isRoadmapOpen } from "stores/roadmap";
import Button, { type ButtonProps } from "./ui/Button";

interface Props extends Partial<ButtonProps> {
  icon?: ReactNode;
}

export default function RoadmapButton({ icon, children, ...props }: Props) {
  const onClick = useCallback(() => {
    $isRoadmapOpen.set(true);
  }, []);

  return (
    <Button
      style="muted"
      className="flex gap-2 items-center justify-center font-semibold text-gray-600"
      onClick={onClick}
      {...props}>
      {icon}
      {children}
    </Button>
  );
}
